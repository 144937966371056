import React, { useState, useEffect, forwardRef } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { Spin, Empty, ColorPicker } from 'antd';

import { Constants } from '../../Utils';
import SVGIcon from '../../icons.js';
import './Common.css';

export function LoadingMask(props) {
    return (
        <div className={`common-mask ${props.show? '' : ' hide'} ${props.transparentBackground? ' transparent' : ''}`}>
            <Spin size={props.size} tip={props.text} />
        </div>
    );
}
LoadingMask.defaultProps = {
    show: false,
    size: 'large',
    text: <FormattedMessage id="loading..." defaultMessage="Loading ..." />,
    transparentBackground: false
};

export function NoDataMask(props) {
    return (
        <Empty className='common-no-data' description={<FormattedMessage id='no_data' />} image={<SVGIcon.NoData />}>
        { props.children }
        </Empty>
    );
}

const ASImageCacheMap = {
    'user': {}  // User Photo, by u_user_id
    // server_type > log_type > log_id
};
export function ASImage(props) {
    const [imgSrc, setImgSrc] = useState(null);
    const [status, setStatus] = useState(0);   // -1: Ignore, 0: Use cache imag, 1: Use parameter image, 2: Image Load Error, 3: force Use Src Image

    useEffect(() => {
        var cacheImg;
        if (props.force && props.src) {
            setStatus(3);
            setImgSrc(props.src);
            return;
        } else if (props.u_user_id > -1) {
            if (props.u_user_id === 0) {
                cacheImg = null;
                setStatus(2);
            } else {
                cacheImg = ASImageCacheMap['user'][props.u_user_id.toString()];
            }
        } else if (props.server_type > -1 && props.log_type > -1 && props.log_id > -1) {
            cacheImg = ASImageCacheMap?.[props.server_type.toString()]?.[props.log_type.toString()]?.[props.log_id.toString()];
        } else {
            if (props.src) {
                setStatus(-1);
                setImgSrc(props.src);
            } else {
                setStatus(2);
                setImgSrc(props.error_image || null);
            }
            return;
        }

        if (cacheImg) {
            setImgSrc(cacheImg);
        } else if (props.src) {
            setStatus(1);
            setImgSrc(props.src);
        } else {
            setStatus(2);
            if (props.u_user_id > -1) {
                setImgSrc(props.error_image || null);
            } else {
                setImgSrc(props.error_image || null);
            }
        }
    }, [props.u_user_id, props.server_type, props.log_type, props.log_id, props.src, props.force, props.error_image]);

    const handleLoad = e => {
        if (status === 1 || status === 3) {
            if (props.u_user_id > -1) {
                ASImageCacheMap['user'][props.u_user_id.toString()] = e.target.src;
            } else if (props.server_type > -1 && props.log_type > -1 && props.log_id > -1) {
                if (typeof(ASImageCacheMap[props.server_type.toString()]) !== 'object') ASImageCacheMap[props.server_type.toString()] = {};
                if (typeof(ASImageCacheMap[props.server_type.toString()][props.log_type.toString()]) !== 'object') ASImageCacheMap[props.server_type.toString()][props.log_type.toString()] = {};
                ASImageCacheMap[props.server_type.toString()][props.log_type.toString()][props.log_id.toString()] = e.target.src;
            }
        }
        if (status === 2) {
            props.onError(e);
        } else {
            props.onLoad(e);
        }
    };

    const handleError = e => {
        if (status === 0) {
            if (props.u_user_id > -1) {
                delete ASImageCacheMap['user'][props.u_user_id.toString()];
            } else if (props.log_type > -1 && props.log_id > -1) {
                delete  ASImageCacheMap[props.server_type.toString()][props.log_type.toString()][props.log_id.toString()];
            }

            if (props.src) {
                setStatus(1);
                setImgSrc(props.src);
            } else {
                setStatus(-1);
                if (props.u_user_id > -1) {
                    setImgSrc(props.error_image || null);
                } else {
                    setImgSrc(props.error_image || null);
                }
            }
        } else if (status === 3) {
            var cacheImg;
            if (props.u_user_id > -1) {
                cacheImg = ASImageCacheMap['user'][props.u_user_id.toString()];
            } else if (props.log_type > -1 && props.log_id > -1) {
                cacheImg = ASImageCacheMap?.[props.server_type.toString()]?.[props.log_type.toString()]?.[props.log_id.toString()];
            }
            if (cacheImg) {
                setStatus(0);
                setImgSrc(cacheImg);
            } else {
                if (props.noErrorImage) {
                    props.onError(e);
                } else {
                    setStatus(2);
                    if (props.u_user_id > -1) {
                        setImgSrc(props.error_image || null);
                    } else if (props.log_type > -1 && props.log_id > -1) {
                        setImgSrc(props.error_image || null);
                    } else {
                        props.onError(e);
                    }
                }
            }
        } else {
            setStatus(2);
            if (props.noErrorImage) {
                props.onError(e);
            } else {
                if (props.u_user_id > -1) {
                    setImgSrc(props.error_image || null);
                } else if (props.log_type > -1 && props.log_id > -1) {
                    setImgSrc(props.error_image || null);
                } else {
                    props.onError(e);
                }
            }
        }
    };

    const { force, noErrorImage, ...newProps } = props;
    return (
        <img {...newProps} alt={props.alt} src={imgSrc} draggable={false} onLoad={handleLoad} onError={handleError} />
    );
}
ASImage.defaultProps = {
    server_type: -1,
    log_type: -1,
    log_id: -1,
    u_user_id: -1,
    src: '',
    alt: '',
    force: false,
    onLoad: function() {},
    onError: function(e) {},
    error_image: null,
    noErrorImage: false
};


const getRecentColors = () => {
    var recentColor = [];
    try {
        recentColor = JSON.parse(window.localStorage.getItem(Constants.storageNames.recentColor));
        if (!Array.isArray(recentColor)) {
            recentColor = [];
        }
    } catch {}
    return recentColor;
};
export const setRecentColors = (colors) => {
    colors = [].concat(colors);
    var recentColor = getRecentColors();
    colors.forEach(color => {
        var index = recentColor.indexOf(color.toLowerCase());
        if (index > -1) {
            recentColor.splice(index, 1);
        }
        recentColor.splice(0, 0, color.toLowerCase());
    });
    while (recentColor.length > 10) {
        recentColor.pop();
    }
    window.localStorage.setItem(Constants.storageNames.recentColor, JSON.stringify(recentColor));
};
export const ASColorPicker = forwardRef((props, ref) => {
    const recommended = ['#000000','#7F7F7F','#880015','#ED1C24','#FF7F27','#FFF200','#22B14C','#00A2E8','#3F48CC','#A349A4',
                         '#FFFFFF','#C3C3C3','#B97A57','#FFAEC9','#FFC90E','#EFE4B0','#B5E61D','#99D9EA','#7092BE','#C8BFE7'];
    const intl = useIntl();
    const [recent, setRecent] = useState([]);
    const [format, setFormat] = useState('rgb');
    useEffect(() => {
        setRecent(getRecentColors());
    }, []);
    const handleChange = (value, hex) => {
        props.onChange(value.toHexString());
    };

    return (
        <ColorPicker {...props} presets={[{label: intl.formatMessage({id: 'recommended_color'}), colors: recommended}, {label: intl.formatMessage({id: 'recent_color'}), colors: recent}]}
            format={format} onFormatChange={value => setFormat(value)}
            onChange={handleChange}
        />
    );
});

export const getTreeSwitcherIcon = (nodeProps) => {
    return <SVGIcon.ArrowDropDown className={nodeProps.expanded ? '' : 'collapsed'} />;
};
export const getCollapseExpandIcon = (panelProps) => {
    return <SVGIcon.ArrowLineDown className={panelProps.isActive ? 'expanded' : ''} />;
};