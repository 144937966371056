import React, { useContext, useState, useEffect, Suspense, useCallback } from 'react';
import { IntlProvider, useIntl } from "react-intl";

import packageJson from '../package.json';
import preval from 'preval.macro';

import { message, Modal } from 'antd';

import { EnumErrorMessage, EnumWebSocketCmd, EnumActionType, EnumThemeType } from './modules/ASUtils/ASConfig';
import { GvJsPlayer } from './modules/ASUtils/ASUtils';
import { AppContext, AppContextProvider, Constants } from './Utils';
import { LoadingMask } from './modules/Common/Common';
import './App.css';

const MainApp = React.lazy(() => import('./modules/MainApp/MainApp'));

const dateTimeStamp = preval`module.exports = new Date().toLocaleDateString() + ' ' + new Date().getHours().toString().padStart(2, '0') + ':' + new Date().getMinutes().toString().padStart(2, '0') + ':' + new Date().getSeconds().toString().padStart(2, '0');`

function Application() {
	const { verifyUser, ajaxLogin, accountInfo, sendWS, supportAccess, addWSNotification, removeWSNotification } = useContext(AppContext);
	const intl = useIntl();
	const [isLogin, setIsLogin] = useState(false);
	const [errMsg, setErrMsg] = useState('');


	useEffect(() => {
		// create meta tags
		createMeta('version', packageJson.version);
		createMeta('build-time', dateTimeStamp);

		verifyUser((accountInfo, supportAccess) => {
			setIsLogin(true);

			if (supportAccess) {
				ajaxLogin((success, accountInfo) => {
					if (success) {
						// setTimeout(() => {	// must get device list for lockdown status
						// 	context.getDeviceList();
						// }, 1000);
					} else {
						setErrMsg(accountInfo);
					}
				});
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		document.body.classList.remove('dark');
		if (accountInfo.theme === EnumThemeType.Dark ||
			(accountInfo.theme === EnumThemeType.OSDefault && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
				
			document.body.classList.add('dark');
		}
	}, [accountInfo.theme]);

	useEffect(() => {
		if (errMsg) {
			message.error(EnumErrorMessage[errMsg] ? intl.formatMessage({id: EnumErrorMessage[errMsg]}) : errMsg);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errMsg]);
	
	const createMeta = (name, content) => {
		var meta = document.createElement('meta');
		meta.name = name;
		meta.content = content;
		document.head.append(meta);
	};

	const receiveNotification = useCallback(data => {
		switch (data.cmd_id) {
			case EnumWebSocketCmd.ACCOUNT_DATA_CHANGED:
				var action_type = parseInt(data.data1);
				var msg = action_type === EnumActionType.Delete ? intl.formatMessage({id: 'account_been_deleted'}) : 
						  action_type === EnumActionType.Edit ? intl.formatMessage({id: 'privilege_changed_reload_page'}) : '';
						
				if (msg) {
					Modal.warning({
						title: process.env.REACT_APP_SITE_TITLE,
						content: msg,
						centered: true,
						afterClose: () => {
							window.location.reload();
						}
					});
					setTimeout(() => {
						window.location.reload();
					}, 10 * 1000);
				}
				break;
			
			case EnumWebSocketCmd.VMS_WEBSOCKET:
				if (data.action === 'LOGOUT') {
					const doLogout = () => {
						sendWS({cmd_id: EnumWebSocketCmd.ACCOUNT_LOGOUT});
						window.localStorage.removeItem(Constants.storageNames.accountInfo);
						window.location = `${Constants.gvCloudUrl}logout?redirect=/map&pathname=${window.location.pathname}`;
					};

					Modal.warning({
						title: process.env.REACT_APP_SITE_TITLE,
						content: intl.formatMessage({id: `VMS_${data.type}`}),
						centered: true,
						afterClose: doLogout
					});
					setTimeout(doLogout, 10 * 1000);
				}
				break;

			default:
				break;
		}
	}, [intl, sendWS]);

	useEffect(() => {
		if (supportAccess) {
			addWSNotification(receiveNotification);
		}
		return () => {
			if (supportAccess) {
				removeWSNotification(receiveNotification);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [supportAccess, receiveNotification]);

	return (
		isLogin ?
		<div className='App'>
			{
				process.env.REACT_APP_TYPE === 'debug' &&
				<div className='app-debug'>{`Debug-${packageJson.version}-${dateTimeStamp}`}</div>
			}
			<Suspense fallback={<LoadingMask show={true} />}>
				<MainApp />
			</Suspense>
		</div>
		:
		<LoadingMask show={true} />
	);
}

export default function App() {
	const [lang, setLang] = useState('en');
	const [locale, setLocale] = useState({});

	useEffect(() => {
		async function fetchData() {
			const resp = await fetch(`${window.location.origin}/lang/${lang}.json?${dateTimeStamp}`);
			const data = await resp.json();
			const asresp = await fetch(`${window.location.origin}/lang/as-${lang}.json?${dateTimeStamp}`);
			const asdata = await asresp.json();
			setLocale({
				...asdata,
				...data
			});
		}
		fetchData();	
	}, [lang]);

	return (
		<IntlProvider messages={locale} locale={lang}>
			<AppContextProvider onLangChange={lang => setLang(lang)}>
				<GvJsPlayer />
				<Application/>
			</AppContextProvider>
		</IntlProvider>
	);
}

